<template>
  <b-form-checkbox
    :class="`custom-control-${variant}`"
    :checked="value"
    :value="true"
    :unchecked-value="false"
    switch
    inline
    @input="updateValue"
  >
    {{ label }}
  </b-form-checkbox>
</template>

<script>

export default {
  name: 'SwitchButton',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    trueLabel: {
      type: String,
      default: ''
    },
    falseLabel: {
      type: String,
      default: ''
    },
    trueVariant: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    label () {
      return this.value ? this.trueLabel : this.falseLabel
    },
    variant () {
      return this.value ? this.trueVariant : 'secondary'
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>
